<script>
import Layout from "@/router/layouts/franchise";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";
import VueMonthlyPicker from "vue-monthly-picker";
import moment from "moment";

export default {
  locales: {
    en: {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    pt: {
      Extract: "Carteira › Extrato",
      Date: "Data",
      Type: "Tipo",
      Description: "Descrição",
      Value: "Valor",
      "Day Balance": "Saldo do Dia",
      Balance: "Saldo",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",

      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
    },
    es: {
      Extract: "Carteira › Extracto",
      Date: "Data",
      Type: "Tipo",
      Description: "Descripción",
      Value: "Valor",
      "Day Balance": "Equilibrio Día",
      Balance: "Equilibrio",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",

      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
    },
  },
  components: { Layout, VclList, Stat, VueMonthlyPicker },
  data() {
    return {
      calendar: "",
      monthly: {
        placeHolder: moment().format("MMM/YYYY"),
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      date: moment().format("YYYY-MM"),

      tableHeade: ["Date", "Type", "Description", "Value", "Balance"],
      tableBody: null,

      loading: true,
      errored: false,
      empty: false,

      statData: null,
    };
  },
  methods: {
    getBalance() {
      api.get("franchise/points/balance").then((response) => {
        if (response.data.status == "success") {
          this.statData = [
            {
              icon: "bx bx-dollar",
              title: "Current Balance",
              value: this.$options.filters.points(response.data.balance),
            },
          ];
        }
      });
    },
    getList() {
      this.tableBody = null;

      this.loading = true;
      this.errored = false;
      this.empty = false;

      api
        .get("franchise/points/extract?date=" + this.date)
        .then((response) => {
          if (response.data.status == "success") {
            this.tableBody = response.data.list;
          } else {
            this.tableBody = null;
          }
        })
        .catch((error) => {
          this.errored = error;
        })
        .finally(() => {
          this.loading = false;
          if (this.tableBody == "" || this.tableBody == null) {
            this.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getBalance();
    this.getList();
  },
  watch: {
    calendar: function (v) {
      this.date = moment(v._d).format("YYYY-MM");
      this.getList();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Pontuações › {{ t("Extract") }}</h4>
          <div class="page-title-right">
            <vue-monthly-picker v-model="calendar" dateFormat="MMM/YYYY" :placeHolder="monthly.placeHolder" :monthLabels="monthly.labels" :clearOption="false" alignment="center"></vue-monthly-picker>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body notranslate">
            <div v-if="errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="empty">{{ t("No records found.") }}</div>
            <vcl-list v-if="loading" class="col-lg-6"></vcl-list>

            <div v-else v-for="(td, index) in tableBody" :key="index" class="pl-4" :class="{ 'bg-soft-light': td.line === 'balance' }">
              <div class="position-relative" :class="{
                'border-gray': index != 0 && index != tableBody.length - 1,
                'dote-before': index == 0,
                'dote-line': tableBody.length == index + 1,
              }" :key="index">
                <div v-if="td.line === 'balance'">
                  <i :class="td.icon +
                    ' h3 position-absolute ml-1 mt-1 bg-white rounded-circle'
                    " style="color: #c8cbd0; left: -17px; top: 10px"></i>
                  <div class="pl-4 pt-3 pb-3">
                    <div class="pt-0 d-flex align-items-center justify-content-center">
                      <div class="flex-fill">
                        <strong class="font-size-12 text-uppercase">{{
                          t(td.type)
                          }}</strong><br />
                        <span class="pr-2">{{ td.balance | points }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <i :class="td.icon +
                    ' h3 position-absolute ml-1 mt-1 bg-white rounded-circle'
                    " style="color: #c8cbd0; left: -17px; top: 30px"></i>
                  <div class="pl-4 pt-3 pb-3">
                    <div class="pt-0 d-flex align-items-center">
                      <div class="flex-fill pr-2">
                        <b style="color: #c8cbd0">{{ td.date }} </b><br />
                        <strong class="font-size-12">{{ td.type }}</strong><br />
                        <span v-if="td.description" class="font-weight-normal">{{ td.description }}<br /></span>
                        <span class="pr-2">{{ td.value | points }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="css">
.border-gray {
  border-left: 2px solid #c8cbd0;
}

.dote-before::before {
  content: "";
  width: 2px;
  height: calc(100%);
  background: #c8cbd0;
  position: absolute;
  color: #fff;
  text-align: center;
  left: 0px;
  top: 35px;
}

.dote-before i {
  left: -15px !important;
}

.dote-line::after {
  content: "";
  width: 2px;
  height: calc(100% - 20px);
  background: #c8cbd0;
  position: absolute;
  color: #fff;
  text-align: center;
  left: 0;
  top: -40px;
}

.dote-line i {
  left: -15px !important;
}
</style>
